<template>
  <div class="home">
     <div @click="close" class="box8" v-show="showModal">

    </div>
 <div class="yuYUE" v-show="showModal">
    <img src="../assets/GAT/close.png" @click="close">
    <input v-model="textst" placeholder="請輸入手機號" @keyup="asses">
    <img @click="submit" src="../assets/GAT/yu_btn.png">
    <div>
      <div @click="checkSTARs">
        <img src="../assets/GAT/checkN.png" v-show="checkModal">
      </div>
      <span @click="checkSTAR">我已閱讀並同意隱私政策</span>
    </div>
     <input v-model="valuesL">
    <div>
      <!--  -->
      <i @click="openIcon" class="el-icon-caret-bottom"></i>
    </div>
      <div v-show="iconAgree">
        <div v-for="item in optionsw" :key="item.value">
          <span>{{item.lable}}{{item.value}}</span>
          <span><button @click="sure1(item)">确定</button></span>
        </div>
        <img @click="closeLY" src="../assets/GAT/close.png">
      </div>
      <div class="radioSelect">
            <div class="select_content">
              <label for="android">
                <div
                  style="
                    border: black 1px solid;
                    width: 10px;
                    height: 10px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-top:1px;
                  "
                >
                  <div
                    v-if="checked == 1"
                    style="width: 8px; height: 8px; background-color: black"
                  ></div>
                </div>
              </label>
              <input
                type="radio"
                id="android"
                v-model="checked"
                value="1"
                @change="handleRadio"
                v-show="false"
              />
              <span class="label">Android</span>
            </div>
            <div class="select_content">
              <label for="ios">
                <div
                  style="
                    border: black 1px solid;
                    width: 10px;
                    height: 10px;
                    display: flex;
                    align-items:center;
                    justify-content:center;
                     margin-top:1px;
                   
                  "
                >
                  <div
                    v-if="checked == 2"
                    style="width: 8px; height: 8px; background-color: black"
                  ></div>
                </div>
              </label>
              <input
                type="radio"
                id="ios"
                v-model="checked"
                value="2"
                @change="handleRadio"
                v-show="false"
              />
              <span class="label">IOS</span>
            </div>
          </div>
  </div>
  <div class="Privacy" v-show="AgreeModal">
    <div class="PrivacyAG">
      {{indexx}}
      <button @click="AgreeBtn">我已閱讀並同意</button>
    </div>
    
  </div>
    <!-- topBar -->
    <div class="menu">
      <!-- <div @click="paymoney" class="paymoney">
        <img src="../assets/GAT/loginPay.png" alt="">
      </div> -->
      <div class="shareBox">
        <a href="https://business.facebook.com/sgtqzz/?business_id=856864411082233" target="_blank" class="share">
          <img src="../assets/GAT/shareicon.png">
        </a>
        <a href="https://www.facebook.com/sgtqzz" target="_blank" class="cafe">
          <img src="../assets/GAT/facebook.png">
        </a>
       
      </div>
    </div>
      <div v-if="today < 1657758600000" class="FuChan">
      <a href="https://www.facebook.com/sgtqzz" target="_blank">
        <img src="../assets/GAT/fuchaun1.png">
      </a>
      <a href="https://www.facebook.com/sgtqzz" target="_blank">
        <img src="../assets/GAT/fuchaung2.png">
      </a>
      <a href="https://www.facebook.com/sgtqzz" target="_blank">
        <img src="../assets/GAT/fuchuang3.png">
      </a>
    </div>
    <div class="FuChan2" v-if="today > 1657758600000">
      <a href="https://apps.apple.com/tw/app/%E5%A4%A9%E5%95%9F-%E6%88%B0%E7%88%AD/id1619235047" target="_blank">
        <img src="../assets/GAT/fuzhuang3.png">
      </a>
      <a href="https://play.google.com/store/apps/details?id=com.ulugame.sgdtsgat.google" target="_blank">
        <img src="../assets/GAT/fuzhuang4.png">
      </a>
      <a href="https://downloadapp.ulugame.com/sgdts_tw/signed_com.ulugame.sgdtsgat.mycard_104_715.apk">
        <img src="../assets/GAT/fuchuang2.png">
      </a>
      <a href="https://www.facebook.com/groups/1686155745083769" target="_blank">
        <img src="../assets/GAT/fuzhuang1.png">
      </a>
    </div>
    <!-- siderbar -->
    <!-- swiper -->
    
    <swiper :options="swiperOption" ref="mySwiper" class="swiper-v">
      <!-- slides -->
      <swiper-slide data-hash="0">
        <div
          class="bg1"
          :style="{
            backgroundImage: `url(${
              today < 1643212800000
                ? require('../assets/GAT/BG_1.png')
                : require('../assets/GAT/BG_1.png')
            })`,
          }"
        >
        <div v-if="today < 1657758600000" class="bg_center">
          <a @click="goToStores" target="_blank">
            <img src="../assets/GAT/yubottom1.png" @click="goToStore(1)">
          </a>
         <a @click="goToStoresL" target="_blank">
           <img src="../assets/GAT/yubottom3.png" @click="goToStore(2)">
         </a>
         
        </div>
        <div class="bg_center2" v-if="today > 1657758600000">
              
            <a href="https://pay.ulugame.com/#/">
            <img src="../assets/GAT/mycard.png">
          </a>
         <!-- <a href="https://apps.apple.com/tw/app/%E5%A4%A9%E5%95%9F-%E6%88%B0%E7%88%AD/id1619235047" target="_blank">
           <img src="../assets/GAT/App_store.png" @click="goToStore(2)">
         </a> -->
        </div>
        <img>
        <img
            src="~@/assets/scroll.png"
            alt
            @click="pageNext(true)"
            class="scroll"
          />
        </div>
      </swiper-slide>

      <swiper-slide data-hash="3" v-if="today < 1657758600000" style="display: flex; flex-direction: column" >
        <div
          class="bg4"
          :style="{
            backgroundImage: `url(
              ${
                today < 1643212800000
                  ? require('../assets/GAT/BG2.png')
                  : require('../assets/GAT/BG2.png')
              })`,
          }"
        >
        <img src="../assets/GAT/yuyue_top.png">
        <!-- <img src="../assets/GAT/yuyue_top2.png"> -->
          <div class="part4">
            <div class="box">
              <a>
                <a class="e1"
                :style="{
                  backgroundImage:`url(
                    ${
                      today < 1655827200000
                      ? require('../assets/GAT/events3.png'):require('../assets/GAT/event3.png')})`}"></a>
              </a>
              <a>
                <a class="e2"
                :style="{
                  backgroundImage:`url(
                    ${
                      today < 1656604800000
                      ? require('../assets/GAT/events4.png'):require('../assets/GAT/event4.png')
                    }
                  )`
                }"
                ></a>
            
              </a>
              <a>
                <a class="e3"
                 :style="{
                  backgroundImage:`url(
                    ${
                      today < 1657728000000
                      ? require('../assets/GAT/events5.png'):require('../assets/GAT/event5.png')
                    }
                  )`
                }" 
                ></a>
              </a>
            
            </div>

            <div
             class="e4"
             v-if="today < 1657728000000"
             @click="startF"
            >
            <!-- <img src="../assets/GAT/yuyue_button.png"> -->
            </div>
             <div class="yu_bottom">
               <div class="yu_bottoms">
                 <a @click="goToStores3">
                   <img src="../assets/GAT/yubottom1.png" @click="goToStore(1)">
                 </a>
                <a @click="goToStores4">
                  <img src="../assets/GAT/yubottom3.png" @click="goToStore(2)">
                </a>
            </div>
          </div>
          </div>
         
        </div>
        
      </swiper-slide>
      <!-- new -->

      <swiper-slide data-hash="4">
        <div
          class="bg3"
        >
              <swiper
              @slideChange="slideChange"
              class="swiper-h"
              ref="mSwiper"
              :options="{
                loop: true,
                autoplay: true,
              }"
              
            >
             <!-- style="background: black; width: 100%; height: 100%" -->
              <swiper-slide
                class="bottom_img"
                :index="index"
                v-for="(item, index) in 3"
                :key="index"
              >
          
              <!-- style="background: gray; width: 100%; height: 100%" -->
                <img
                  :src="
                    today < 1643212800000
                      ? require(`../assets/GAT/LB${index + 1}.png`)
                      : require(`../assets/GAT/LB${index + 1}.png`)
                  "
                  alt
                />
              </swiper-slide>
            </swiper>
        <div class="part3">
        <img
              class="Lroad"
              src="~@/assets/GAT/L_road.png"
              @click="lightIt(i, 'min')"
            />
  
            <img
              @click="lightIt(i, 'plus')"
              src="~@/assets/GAT/R_road.png"
              class="Rroad"
            />
        </div>
         
        </div>
        <div class="swiper-scrollbar" slot="scrollbar">
          <div class="swiper-scrollbar-drag"></div>
        </div>
      </swiper-slide>
      <!-- 底部ulugame -->
      <swiper-slide data-hash="4" class="swiper-footer">
        <div class="part5">
          <img src="../assets/GAT/logo_bottom.png">
          <img src="../assets/GAT/logo_bottom2.jpg">
          <div>
            <p>
              ※ 本軟體因涉及暴力、性（遊戲角色穿著凸顯性特徵之服飾），依遊戲軟體分級管理辦法分類為輔12級。
            </p>
            <p>
              ※ 本遊戲為免費使用，遊戲內另提供購買虛擬遊戲幣、物品等付費服務。
            </p>
            <p>
              ※ 長時間進行遊戲，請注意使用時間，避免沉迷於遊戲。
            </p>
          </div>
        </div>
      </swiper-slide>
      <!-- Optional controls -->
      <div class="swiper-scrollbar" slot="scrollbar"></div>
    </swiper>
    
    <!-- pop video -->
    <div v-if="pup.video">
      <div class="mfp-bg"></div>
      <div class="videoPlayer">
        <span @click="pup.video = false" class="closeShare"></span>
        <img src alt />
        <iframe
          ref="video"
          width="1024"
          height="576"
          src="https://www.youtube.com/embed/v-yVUXuNzI0?rel=0&amp;autoplay=1"
          frameborder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
      </div>
    </div>
    <!-- popup -->
    <div v-show="pup.wait">
      <div class="mfp-bg"></div>
      <div class="pop-wait">
        <div class="ok" @click="closeWait"></div>
      </div>
    </div>
    <!-- popup share -->
    <div class="shareSoft" v-show="pup.shareSoft">
      <div class="mfp-bg"></div>
      <div class="pop-share">
        <!-- <img
          src="~@/assets/share_title.png"
          style="
            position: absolute;
            top: 38px;
            left: 50%;
            transform: translateX(-50%);
          "
        /> -->
        <span @click="pup.shareSoft = false" class="closeShare1"></span>
        <div class="shareIcon">
          <ShareNetwork
            network="facebook"
            :url="baseUrl"
            title="<2X>사전예약 페이지"
            description="막힘없은 탑 클래스!  인기 MMORPG <2X> 사전예약 및 이벤트 진행 중!"
            hashtags="공식 홈페이지"
          >
            <img src="~@/assets/face_btn.png" alt />
          </ShareNetwork>
     
          <div id="kakaostory-share-button">
            <a href="https://open.kakao.com/o/glBKN2Pd"></a>
          </div>
        </div>
      </div>
    </div>
    <!-- popup act tip -->
    <div v-show="pup.actTip">
      <div class="mfp-bg"></div>
      <div class="actBox position" @click="pup.actTip = false">
        <!-- <img
          src="~@/assets/close_share.png"
          class="closeBtn"
          @click="pup.actTip = false"
        /> -->
      </div>
    </div>
    <!-- popup info collect -->
    <div v-show="pup.infoTip">
      <div class="mfp-bg"></div>
      <div class="infoBox position" @click="pup.infoTip = false">
        <!-- <img
          src="~@/assets/closeBtn.png"
          class="closeBtn"
          @click="pup.infoTip = false"
        />-->
      </div>
    </div>
    <!--popup submit success -->
    <div v-show="pup.submitSuccess">
      <div class="mfp-bg"></div>
      <div class="successBox position">
        <img
          src="~@/assets/close_share.png"
          class="closeBox"
          @click="pup.submitSuccess = false"
        />
        <div class="btnBoth">
          <a href="javascript:;" @click="setPage">
            <img src="~@/assets/leftBtn.png" alt />
          </a>
          <a href="https://cafe.naver.com/sgdts" target="_blank">
            <img src="~@/assets/rightBtn.png" alt />
          </a>
        </div>
      </div>
    </div>
    <!-- popup error tip -->
    <div v-show="pup.errorTip">
      <div class="mfp-bg"></div>
      <div class="errorBox position">
        <div class="tipText" v-html="tip"></div>
        <img
          src="~@/assets/closeBtn.png"
          class="closeBtn"
          @click="pup.errorTip = false"
        />
      </div>
    </div>
    <!-- pop night  -->
    <div v-show="pup.night">
      <div class="mfp-bg"></div>
      <div class="night position" @click="pup.night = false">
        <!-- <img class="closeBtn" /> -->
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
const selected = require("@/assets/checked.png");
const select = require("@/assets/check.png");
import { request } from "@/util/index.js";
import indexx from "@/util/indexx.js"
// import vuedebounce from "@/util/vuedebounce.js"
let today = new Date().getTime();
//import { Carousel3d, Slide } from "vue-carousel-3d";
export default {
  name: "carrousel",
  data() {
    return {
      indexx,
      textst:'',
      times:null,
      valuesL:'',
      today,
      radio: '1',
      swiperOption: {
        observer: true,
        direction: "vertical",
        slidesPerView: "auto",
        spaceBetween: 0,
        mousewheel: true,
        hashNavigation: true,
      },
      otherOption: {
        observer: true,
        slidesPerView: "auto",
        spaceBetween: 0,
      },
      imgs: [selected, select],
      isClassList: [true, false, false, false, false],
      swiperOption1: {
        grabCursor: true,
        slidesPerView: 1,
        spaceBetween: 0,
        loop: true,
        centeredSlides: true,
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
      },
      baseUrl: process.env.VUE_APP_BASEURL,
      pup: {
        wait: false,
        show: false,
        errorTip: false,
        actTip: false,
        infoTip: false,
        night: false,
        submitSuccess: false,
        shareSoft: false,
        video: false,
      },
      inputVal: "",
      actvie: 0,
      checked: 1,
      isCheck: 1,
      isCheck1: 1,
      isCheck2: 1,
      // imgs: [selected, select],
      chosen: [selected, selected, selected],
      tip: "",
      i: 0,
      imgList: [
        "puzzle_1",
        "puzzle_2",
        "puzzle_3",
        "puzzle_4",
        "puzzle_5",
        "puzzle_6",
        "puzzle_7",
        "puzzle_8",
        "puzzle_9",
      ],
      showModal:false,
      checkModal:true,
      AgreeModal:false,
      iconAgree:false,
      optionsw:[{
        value:'886',
        lable:'台湾+'
      },
      {
        value:'852',
        lable:'香港+'
      },
      {
        value:'853',
        lable:'澳门+'
      }
      ],
      MobileValue:'',
      values:''
    };
  },

  computed: {
     swiper() {
      return this.$refs.mySwiper.swiper;
    },
    mSwiper() {
      return this.$refs.mSwiper.swiper;
    },
    newImgList() {
      return this.imgList.map((item, index) => {
        return { img: require(`@/assets/${item}.png`), show: index !== 0 };
      });
    },
  },
  watch: {
 
     
  },
  created() {
    
  },
  async mounted() {
      await fetch(`${this.baseUrl}/api/website/reserve/web/accessCount`, {
      method: "PUT",
      body: JSON.stringify({
        gameId: "100163",
        activityId: "13",
      }),
      headers: new Headers({
        "Content-Type": "application/json",
      }),
    });
  },
  methods: {

    AgreeBtn(){
      this.AgreeModal=false
      this.checkModal=true
    },
    startF(){
      this.showModal=true
      // window.gtag('event', 'conversion', {
      //         'send_to': 'AW-10928547223/utZICN-P4McDEJfLkdso',
      //         'event_callback': callback
              
      //     });
    },
    close(){
      this.showModal=false
      if(this.AgreeModal){
        this.showModal=true
      }
      if(this.iconAgree){
        this.showModal=true
      }
      this.textst=''
      this.valuesL=''
    },
    changesLY(){
      console.log(this.radio)
    },
    checkSTAR(){
      this.AgreeModal=true
    },
    checkSTARs(){
      this.checkModal=!this.checkModal
    },
    openIcon(){
      this.iconAgree=true
    },
    closeLY(){
      this.iconAgree=false
      
    },
    sure1(value){
 
      this.valuesL=value.lable+value.value
      this.MobileValue = value.value
      
      this.iconAgree=false
    },
    asses(value){
      if(this.times){
        clearTimeout(this.times)
      }
      this.values = value.srcElement._value
      // this.times = setTimeout(()=>{
      //   let values = value.srcElement._value
      //   console.log(values)
      //   let rew = /^\d{9}$|^\d{8}$/
      // if(rew.test(values)){
      //   console.log('正确手機號碼')
      // }else{
      //  this.$message.error('请输入正确的手机号');
      // }
      // },2000);
    },
    //   scrollIt(e) {
    //   //   console.log(8899, e.target.scrollTop);
    //   if (e.target.scrollTop < window.innerHeight) {
    //     this.actvie = 0;
    //   } else if (e.target.scrollTop < window.innerHeight * 2) {
    //     this.actvie = 1;
    //   } else if (e.target.scrollTop < window.innerHeight * 3) {
    //     this.actvie = 2;
    //   } else if (e.target.scrollTop < window.innerHeight * 4) {
    //     this.actvie = 3;
    //   }
    // },
    slideChange() {
        this.i = this.mSwiper.activeIndex || 0;
      this.isClassList = this.isClassList.map(() => {
        return false;
      });
      this.$set(this.isClassList, (this.i + 4) % 5, true);
      //   console.log(7883, s);
      //   $("#showhtml").html("函数触发了");
    },
   lightIt(index = 0, type) {
      //console.log(index, this.mSwiper.activeIndex);
      if (type instanceof String) {
        this.$refs.mSwiper.swiper.slideTo(index, 500, false);
        return;
      }
      let i = this.mSwiper.activeIndex;
      // i = index + 1 || 0;
      this.isClassList = this.isClassList.map(() => {
        return false;
      });
      if (type === "plus") {
        if (i > 2) {
          i = 0;
        }
        i++;
      } else if (type === "min") {
        if (i < 1) {
          i = 3;
        }
        i--;
      }

      this.i = i;
      this.$refs.mSwiper.swiper.slideTo(Math.abs(i) % 6, 500, false);
      this.$set(this.isClassList, (Math.abs(i) % 6) - 1, true);
    },
    goToStores(){
      return window.gtag_report_conversion('https://play.google.com/store/apps/details?id=com.ulugame.sgdtsgat.google')
    },
    goToStoresL(){
      return window.gtag_report_conversion('https://apps.apple.com/tw/app/id1619235047')
    },
    goToStores3(){
      return window.gtag_report_conversion('https://play.google.com/store/apps/details?id=com.ulugame.sgdtsgat.google')
    },
    goToStores4(){
      return window.gtag_report_conversion('https://apps.apple.com/tw/app/id1619235047')
    },
    goToStore(channelStore){
      this.setData();
      console.log(channelStore)
        request({
        url: "/api/website/reserve/web/channelStore",
        data: {
          activityId: "13", //活动ID
          email: "",
          mobile: this.inputVal,
          channelStore: channelStore,
        },
      });
    },
    submit() {
      this.setData();
      let rew = /^\d{9}$|^\d{8}$/
      if(rew.test(this.values) == false){
        this.$message.error('請準確輸入手機號碼')
        return
      }else if(this.checkModal==false){
        this.$message.error('請勾選隱私政策')
      }else if(this.valuesL!=''){
      const media = this.$route.params.id || "direct";
      fetch(`${this.baseUrl}/api/website/reserve/web/preRegister`, {
        method: "PUT",
        body: JSON.stringify({
          gameId: "100163",
          activityId: "13",
          channelId: this.checked,
          mobile: this.MobileValue+this.textst,
          platform: "1",
          media,
          email:'',
        }),
        headers: new Headers({
          "Content-Type": "application/json",
        }),
      })
        .then((res) => {
          return res.json();
        })
        .catch((error) => console.error("Error:", error))
        .then((response) => {
          if (response.code == 0) {
            this.showModal=false
            this.$message.success('預約成功')
            this.textst=''
            this.valuesL=''
          }else if(response.code == 40516){
            this.$message.error('請勿重複預約')
          }else if(response.code == 40515){
             this.$message.error('手機號不能爲空')
          }else if(response.code == 30100){
             this.$message.error('請準確輸入手機號碼')
          }else if(response.code == 40703){
            this.$message.error('活動已結束')
          }
        });
      }else if(this.textst==''){
          this.$message.error('請輸入手機號碼')
      }else{
        const media = this.$route.params.id || "direct";
        fetch(`${this.baseUrl}/api/website/reserve/web/preRegister`, {
        method: "PUT",
        body: JSON.stringify({
          gameId: "100163",
          activityId: "13",
          channelId: this.checked,
          mobile: this.textst,
          platform: "1",
          media,
          email:'',
        }),
        headers: new Headers({
          "Content-Type": "application/json",
        }),
      })
        .then((res) => {
          return res.json();
        })
        .catch((error) => console.error("Error:", error))
        .then((response) => {
          if (response.code == 0) {
            this.showModal=false
            this.$message.success('預約成功')
            this.textst=''
            this.valuesL=''
          }else if(response.code == 40516){
            this.$message.error('請勿重複預約')
          }else if(response.code == 40515){
             this.$message.error('手機號不能爲空')
          }else if(response.code == 30100){
             this.$message.error('請準確輸入手機號碼')
          }else if(response.code == 40703){
            this.$message.error('活動已結束')
          }
        });
      }
      // return window.gtag_report_conversion('https://apps.apple.com/tw/app/id1619235047')
 
    },
    handleRadio(){
      console.log(this.checked)
    },
      handleCheck(x, e) {
      if (e.target.checked) {
        this.chosen[x] = selected;
      } else {
        this.chosen[x] = select;
      }
    },
    setPage() {
      this.pup.submitSuccess = false;
      this.swiper.slideTo(3, 500, false);
    },
    pageNext(val) {
      if (val) {
        console.log(888, val);
        if (typeof val === "number") {
          console.log(888, val);
          this.swiper.slideTo(val, 500, false);
          return;
        }
        this.swiper.slideNext();
      } else {
        this.swiper.slidePrev();
      }
    },
        maxlength(c) {
      c = c.toString();
      let maxlength = 1,
        length = 1,
        char = "";
      for (let index in c) {
        var tmp = c[index];
        if (char == tmp) {
          length += 1;
        } else {
          char = tmp;
          if (length > maxlength) {
            maxlength = length;
          }
          length = 1;
          char = tmp;
        }
      }
      maxlength = maxlength > length ? maxlength : length;
      return maxlength;
    },
    closeWait() {
      this.pup.wait = false;
    },
    //埋点
    setData() {
      window.fbq && window.fbq("track", "CompleteRegistration");
      console.log(456798);
      window.ttq.track("CompleteRegistration");
      console.log(123456);
      // window.gtag(
      //   'send',
      //   {
      //     hitType: 'event',
      //     eventCategory: 'a',
      //     eventAction:'Click'
      //   }
      // );
      console.log(888888);
    },
  },
  beforeDestroy(){
            clearTimeout(this.times);
          }
};
</script>
<style lang="scss">
html,
body,
.home {
  position: relative;
  height: 100%;
  width: 100%;
}
.box8{
  width: 100vw;
  height: 100vh;
  background-image: url("../assets/GAT/greyBG.png");
  background-size: 100% 100%;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99;
}
  .yuYUE{
    width: 39.06vw;
    height: 13.17vw;
    background-image: url("../assets/GAT/yuyue.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    position: fixed;
   margin: 0 auto;
   left: 0;
   right: 0;
   top: 40%;
    z-index: 999;
    &>:nth-child(1){
      width: 1.04vw;
      height: 1.04vw;
      position: absolute;
      left: 37.44vw;
      top: 0.57vw;
      cursor: pointer;
    }
     &>:nth-child(2){
      width: 12.39vw;
      height: 1.7vw;
      position: absolute;
      left: 16.83vw;
      top: 5.36vw;
      background: #000;
      color: #fff;
      box-sizing: border-box;
      
    }
    &>:nth-child(3){
      width: 7.29vw;
      height: 2.18vw;
      position: absolute;
      top: 8.85vw;
      left: 15.88vw;
      cursor: pointer;
    }
     &>:nth-child(4){
      width: 20.41vw;
      position: absolute;
      top: 11.82vw;
      display: flex;
      left: 14.84vw;
      color: #fff;
      &>:nth-child(1){
        width: 0.88vw;
        height: 0.833vw;
        background: #000;
        cursor: pointer;
        margin-right: 0.26vw;
        img{
          width:1.04vw;
          height:1.04vw;
        }
      }
      &>:nth-child(2){
        width: 14.58vw;
        height: 0.62vw;
        font-size: 0.8rem;
        color: #000;
        cursor: pointer;
      }
    }
     &>:nth-child(5){
       width: 5vw;
       height: 1.7vw;
       position: absolute;
       top: 5.36vw;
       left: 9.98vw;
      background: #000;
      box-sizing: border-box;
     color: #fff;
     }
     &>:nth-child(6){
        width: 1.8vw;
        height: 1.7vw;
        // border-bottom:1px solid grey;
        background: blue;
        position: absolute;
        top: 5.36vw;
        left: 15vw;
      .el-icon-caret-bottom{
        display: block;
        width: 1vw;
        height: 1vw;
        color: #fff;
        // margin: 0 auto;
        margin-left: 0.2vw;
        margin-top: 0.2vw;
        font-size: 1.4vw;
        cursor: pointer;
      }
     }
       &>:nth-child(7){
        width: 26.04vw;
        height: 20.04vw;
        position: fixed;
        margin: 0 auto;
        left: 0;
        right: 0;
        top:30%;
        z-index: 999;
        background: #fff;
        &>:nth-child(1){
          margin-left: 2vw;
          border-bottom: 1px solid grey;
          margin-right: 2vw;
          margin-top: 5vw;
          display: flex;
          color: #000;
          justify-content: space-between;
          &>:nth-child(2){
            &>:nth-child(1){
              cursor: pointer;
            }
            margin-left: 5vw;
            
          }
        }
        &>:nth-child(2){
          margin-left: 2vw;
          border-bottom: 1px solid grey;
          margin-right: 2vw;
          margin-top: 3vw;
          display: flex;
          color: #000;
          justify-content: space-between;
          &>:nth-child(2){
            margin-left: 5vw;
            &>:nth-child(1){
              cursor: pointer;
            }
          }
        }
        &>:nth-child(3){
          margin-left: 2vw;
          border-bottom: 1px solid grey;
          margin-right: 2vw;
          margin-top: 3vw;
          display: flex;
          color: #000;
          justify-content: space-between;
          &>:nth-child(2){
            margin-left: 5vw;
          &>:nth-child(1){
              cursor: pointer;
            }
          }
        }
        &>:nth-child(4){
         width: 1vw;
         height: 1vw;
          position: absolute;
          top: 0.5vw;
          right: 0.5vw;
          cursor: pointer;
        }
      }
    .radioSelect {
      display: flex;
      position: absolute;
        left: 15.5vw;
        top: 7.2vw;
      margin-top: 0.52vw;
      align-self: flex-start;
      color: #000;
     
      .os {
        width: 8.22vw;
        height: 1.4vw;
      }

      .select_content {
        display: flex;
        align-self: flex-start;
        margin-right: 2.6vw;
        img {
          
          width: 1.56vw;
          height: 1.56vw;
        }
      }
      .label{
        margin-left: 0.3vw;
      }
    }
   
    input::-ms-input-placeholder{
    color:#fff;
    text-align: center;
    font-size: 500;
   }
    input::-webkit-input-placeholder{
    color:#fff;
    text-align: center;
    font-size: 500;
   }
  }
  .Privacy{
    width: 39.06vw;
    height: 40vw;
    position: fixed;
   margin: 0 auto;
   left: 0;
   right: 0;
   top: 10%;
  z-index: 999;
   overflow: hidden;
  text-overflow: ellipsis;
  background: #000;
  
  h1{
    text-align: center;
  }
  .PrivacyAG{
    width: 30vw;
    height: 35vw;
    margin-left: 5vw;
    margin-top: 1vw;
   overflow-y: scroll;
    white-space: pre-wrap;
  }
 button{
   width: 8vw;
   height: 2vw;
   font-size: 0.8rem;
 margin-left: 30%;
  margin-top: 5%;
  cursor: pointer;
 }
  }
body {
  background: black;
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 14px;
  color: white;
  margin: 0;
  padding: 0;
}

.swiper-container,
.swiper-wrapper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}


.bg2 {
  
  background-position-y: 40%;
 
}
.bg3 {
   
    .swiper-h {
   
    div.carousel-3d-controls {
      a.prev,
      a.next {
        width: 48px !important;
      }
    }
     .bottom_img img{
    width: 100%;
   
  }
  }
  .part3 {
  .Lroad{
      width: 4.37vw;
      height: 6.66;
      position: absolute;
      z-index: 2;
      top: 22.76vw;
      left: 1.4vw;
      cursor: pointer;
  }
  .Rroad{
      width: 9.58vw;
      height: 11.84vw;
      position: absolute;
      z-index: 2;
      top: 20.67vw;
      right: -1.56vw;
      cursor: pointer;
  }
  .button-prev {
    background: url("~@/assets/GAT/L_road.png") no-repeat;
    width: 48px;
    height: 52px;
    cursor: pointer;
    display: inline-block;
    top: 0;
    position: absolute;
    left: 74px;
  }
  .button-next {
    background: url("~@/assets/GAT/R_road.png") no-repeat;
    width: 48px;
    height: 52px;
    cursor: pointer;
    display: inline-block;
    top: 0;
    position: absolute;
    right: 74px;
  }
  // .swiper-button-next:after {
  //   visibility: hidden;
  // }
  // .swiper-button-prev:after {
  //   visibility: hidden;
  // }
}
}
.bg4 {
  // background: #000 url(~@/assets/bg3.jpg) center 15% no-repeat;
  display: flex;
  height: 100%;
  width: 100%;
  position: relative;
  background-size:100% 100%;
  justify-content: center;
  background-position-y: 40%;
  &>:nth-child(1){
     width: 35.2vw;
     height: 9.84vw;
     position: absolute;
     top: 4.16vw;
     left: 31.92vw;
  }
}
.bg5 {
  background: #000 url(~@/assets/bg5.jpg) center 15% no-repeat;
  display: flex;
  justify-content: center;
}


.bg5,
.bg3 {
  width: 100%;
  height: 100%;
  background-size: 100% 100%;
  position: relative;
  background-position: top;
}
.bg1 {
  width: 100vw;
  height: 100vh;
  background-position: 50% 50%;
   background-position: top;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  position: relative;
  .bg_center{
    background-image: url('../assets/GAT/bg_center.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    width: 44.32vw;
    height: 18.43vw;
    position: absolute;
    top: 60vh;
    // top: 31vw;
    left: 28vw;
    :nth-child(1){
      &>:nth-child(1){
      width: 10.42vw;
      height: 3.33vw;
      cursor: pointer;
      position: absolute;
      left: 20.57vw;
      top: 12.2vw;
      }
    }
    :nth-child(2){
      &>:nth-child(1){
        width: 10.54vw;
      height: 3.5vw;
      cursor: pointer;
      position: absolute;
      left: 31.25vw;
      top: 12.15vw;
      }
    }
  }
  .bg_center2{
    &>:nth-child(1){
      &>:nth-child(1){
      width: 12.42vw;
      height: 3.83vw;
      cursor: pointer;
      position: absolute;
      left: 44%;
      bottom:4vw;
      border-radius: 6px;
      }
    }
  }
.scroll {
  position: absolute;
  margin: 0 auto;
  bottom: -0.4vw;
  left: 0;
  right: 0;
  z-index: 21;
  cursor: pointer;
  transform: scale(0.8);
}
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
  transform: translate(0, 0);
  transition: 0.3s ease;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  transform: translate(-301px, 0);
  transition: 0.3s ease;
}
.siderbar {
  position: fixed;
  left: 0px;
  top: 0px;
  width: 301px;
  height: 100%;
  z-index: 158;
  background: white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 18px 17px 40px 0;
  box-sizing: border-box;
  .closeIcon {
    align-self: flex-end;
    cursor: pointer;
    z-index: 10;
  }
  .logo {
    display: block;
    width: 213px;
    height: 104px;
  }
  .menuList {
    position: absolute;
    top: 50%;
    width: 100%;
    transform: translateY(-50%);
    img {
      width: 284px;
      height: 133px;
    }
    // img::after {
    //   content: "2";
    //   height: 100px;
    //   width: 4px;
    //   background: black;
    // }

    .menutitle {
      margin-left: 110px;
      width: 284px;
      position: relative;
    }
    .menutitle::after {
      content: "";
      height: 118px;
      width: 2px;
      background: white;
      position: absolute;
      top: 50%;
      right: 68px;
    }
    .menutitle:nth-child(4)::after {
      height: 0px;
    }

    div {
      cursor: pointer;
    }
    .active {
      margin-left: 110px;
    }
  }
}
.night {
  width: 600px;
  height: 318px;
  background: url(~@/assets/night.png) no-repeat;
  position: absolute;
  .closeBtn {
    width: 126px;
    height: 44px;
    margin: auto;
    left: 0;
    right: 0;
    display: block;
    cursor: pointer;
    position: absolute;
    bottom: 64px;
  }
}
.tipText {
  color: #262626;
  text-align: center;
  font-weight: bold;
  font-size: 18px;
  margin-top: 50px;
}
.closeBox {
  position: absolute;
  right: 0;
  top: -80px;
  cursor: pointer;
}

.position {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  margin: auto;
  z-index: 25;
}
.infoBox {
  width: 599px;
  height: 784px;
  background: url(~@/assets/info1.png) no-repeat;
  .closeBtn {
    width: 126px;
    height: 41px;
    display: block;
    cursor: pointer;
    position: absolute;
    bottom: 59px;
    margin: auto;
    left: 0;
    right: 0;
  }
}
.currentNum {
  background: url(~@/assets/current.png) no-repeat;
  width: 291px;
  height: 67px;
  margin: 0 auto;
  span {
    float: right;
    margin: 14px 48px 0 0;
    font-size: 20px;
  }
}
.errorBox {
  background: white;
  width: 588px;
  height: 195px;

  border-radius: 10px;
  .closeBtn {
    width: 112px;
    height: 41px;
    margin: 46px auto 0 auto;
    display: block;
    cursor: pointer;
  }
}
.successBox {
  width: 30vw;
  height: 32vh;
  background: url(~@/assets/success.png) no-repeat;
  background-size: 100% 100%;
  .btnBoth {
    position: absolute;
    bottom: 50%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    transform: translateY(50%);
    a {
      margin: 0 10px;
    }
  }
}
.actBox {
  width: 599px;
  height: 532px;
  background: url(~@/assets/act.png) no-repeat;
  .closeBtn {
    width: 112px;
    height: 41px;
    position: absolute;
    bottom: 62px;
    display: block;
    cursor: pointer;
    left: 0;
    right: 0;
    margin: auto;
  }
}
.rightIcon {
  position: fixed;
  top: 1vh;
  right: 0;
  bottom: 0;
  margin: auto;
  width: 10vh;
  height: 50vh;
  z-index: 22;
  background-color: white;
  cursor: pointer;
  a,
  span {
    width: 10vh;
    height: 10vh;
    display: block;
  }
  .app0 {
    background: url(~@/assets/logo_url.png) no-repeat;
  }
  .app1 {
    background: url(~@/assets/Google.png) no-repeat;
  }
  .app2 {
    background: url(~@/assets/oneStore.png) no-repeat;
  }
  .app3 {
    background: url(~@/assets/btn3.png) no-repeat;
  }
  .app4 {
    background: url(~@/assets/btn4.png) no-repeat;
  }
}
.pop-wait {
  background: url(~@/assets/bgbox.png) no-repeat;
  width: 599px;
  height: 483px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  z-index: 88;

  .ok {
    width: 120px;
    height: 40px;
    position: absolute;
    bottom: 62px;
    margin: 0 auto;
    left: 0;
    right: 0;
    cursor: pointer;
  }
}
// share
.shareSoft {
  .pop-share {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    z-index: 99;
    width: 836px;
    height: 470px;
    .closeShare1 {
      //close_share
      background: url(~@/assets/close_share.png) no-repeat;
      background-size: 100%;
      position: absolute;
      cursor: pointer;
      right: 20px;
      top: 20px;
      width: 80px;
      height: 80px;
    }
    .shareIcon {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 617px;
      height: 346px;
      margin: 0 auto;
      background-image: url(~@/assets/a_share.png);
      background-repeat: no-repeat;
      margin-top: 96px;

      span {
        margin: 0 4px;
        cursor: pointer;
        float: left;
        width: 92px;
        height: 92px;
      }
    }
  }
}
.closeShare {
  background: url(~@/assets/close_share.png) no-repeat;
  position: absolute;
  cursor: pointer;
  right: 0;
  top: -75px;
  width: 85px;
  height: 85px;
}
.mfp-bg {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 22;
  overflow: hidden;
  position: fixed;
  background: #0b0b0b;
  opacity: 0.8;
}
.menu {
  position: fixed;
  z-index: 20;
  display: flex;
  padding: 25px 25px 0 25px;
  justify-content: space-between;
  box-sizing: border-box;

  .shareBox {
    display: flex;
    justify-content: space-between;
    position: fixed;
    right: 1.5vw;
    top: 1.5vw;
    a {
      width: 3vw;
      height: 3vw;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: 40px;
      img{
        width: 2.66vw;
        height: 2.66vw;
      }
    }

    .share span {
      background: url(~@/assets/GAT/shareicon.png) no-repeat;
      width: 100%;
      height: 100%;
    }
    // .share:hover span {
    //   background: url(~@/assets/shareHover.png) no-repeat;
    // 
    // .cafe:hover span {
    //   background: url(~@/assets/cafeHover.png) no-repeat;
    // }
    .facebook span {
      background: url(~@/assets/GAT/shareicon.png) no-repeat;
    }
    // .facebook:hover span {
    //   background: url(~@/assets/facebookHover.png) no-repeat;
    // }
  }
  .menubar {
    cursor: pointer;
    height: 80px;
  }
  
}
.FuChan{
  position: fixed;
  z-index: 20;
  box-sizing: border-box;
  &>:nth-child(1) img{
    width: 8.19vw;
    height: 15.8vw;
    position: fixed;
    right:1vw;
    top: 5.76vw;
    cursor: pointer;
  }
   &>:nth-child(2) img{
    width: 7vw;
    height: 10vw;
    position: fixed;
    right:1.5vw;
    top: 22.05vw;
    cursor: pointer;
  }
   &>:nth-child(3) img{
    width: 7vw;
    height: 10vw;
    position: fixed;
    right:1.5vw;
    top: 32.7vw;
    cursor: pointer;
  }
}
.FuChan2{
  position: fixed;
  z-index: 20;
  box-sizing: border-box;
  background:url(../assets/GAT/fuzhuang.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  position: absolute;
  width:14.04vw;
  height:21.8vw;
  right:1vw;
  top:15vw;
  &>:nth-child(1) img{
    width: 10vw;
    height: 4vw;
    margin-left: 2vw;
    margin-top: 1vw;
    // position: fixed;
    // right:1.5vw;
    // top: 32.7vw;
    cursor: pointer;
  }
  &>:nth-child(2) img{
    width: 10vw;
    height: 4vw;
    margin-left: 2vw;
    margin-top: 1vw;
    // position: fixed;
    // right:1.5vw;
    // top: 32.7vw;
    cursor: pointer;
  }
   &>:nth-child(3) img{
    width: 10vw;
    height: 4vw;
    margin-left: 2vw;
    margin-top: 1vw;
    // position: fixed;
    // right:1.5vw;
    // top: 32.7vw;
    cursor: pointer;
  }
   &>:nth-child(4) img{
    width: 10vw;
    height: 4vw;
    margin-left: 2vw;
    margin-top: 1vw;
    // position: fixed;
    // right:1.5vw;
    // top: 32.7vw;
    cursor: pointer;
  }
}
.tagContents {
  // background: url(~@/assets/contents.png) no-repeat;
  width: 1080px;
  height: 613px;
  position: relative;
  left: -75px;
  right: 0;
  margin: auto;
  transform: scale(0.8);
}
.part1 {
  position: absolute;
  top: 30vh;
  & > :nth-child(1) {
    width: 16.25vw;
  }
  & > :nth-child(3) {
    background: url(~@/assets/card_bg.png) no-repeat;
    width: 51.77vw;
    height: 30vh;
    background-size: 100% 100%;
    margin-top: -3.3vw;
    display: flex;

    justify-content: flex-end;
    align-items: flex-end;
    & > div {
      display: flex;
      flex-wrap: wrap;
      width: 25vw;
      height: 7vw;
      margin-bottom: 1vh;
      align-content: center;

      & > a {
        & > img {
          width: 10vw;

          margin-left: 0px;
          margin-right: 0vw;
        }
        margin-bottom: 0.5vh;
      }
      & > :nth-child(even) {
        margin-left: 10px;
      }
    }
  }

  img {
    display: block;
    margin: 0 auto;
    cursor: pointer;
  }
  .video_btn {
    width: 10vw;
    margin: 0 auto;
    margin-top: -2vw;
  }
}

.videoPlayer {
  width: 1024px;
  height: 576px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  z-index: 23;
}
#swiper-container2 .swiper-slide {
  // height: 100%;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  display: block;
}


.title3 {
  margin: 2% auto 20px auto;
  display: block;
}
.carousel-3d-slide {
  background-color: transparent !important;
}



.part4 {
  margin-top: 30vh;

  .box {
    display: flex;
    flex-direction: row;
    div {
      width: 17vw;
    }
    a {
      width: 15.5vw;
      height: 21vw;
      margin-right: 1.2vw;
      display: inline-block;
      cursor: pointer;
    }
    img {
      width: 15.7vw;
      height: 4vh;
      cursor: pointer;
    }
    .e1 {
      // background: url("~@/assets/GAT/event3.png") no-repeat;
      background-size: 100% 100%;
    }
    .e2 {
      background: url("~@/assets/GAT/event4.png") no-repeat;
      background-size: 100% 100%;
    }
    .e3 {
      background: url("~@/assets/GAT/event5.png") no-repeat;
      background-size: 100% 100%;
    }
  }

  .btn {
    margin: 0 auto;
    width: 157px;
    height: 150px;
    display: block;
  }
  .e4{
    background: url("~@/assets/GAT/event3_B.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    width: 24.58vw;
    height: 8.02vw;
    margin-left: 24%;
    display: block;
    cursor: pointer;
    margin-top: 2vh;
    &>:nth-child(1){
      width: 11.25vw;
      height: 3.17vw;
      position: absolute;
      margin-left: 6.12vw;
      margin-top: 2.04vw;
    }
  }
   .yu_bottom{
     .yu_bottoms{
       width: 34.7vw;
       position: absolute;
       margin-left: 6.82vw;
       margin-top: 1vw;
    &>:nth-child(1){
      &>:nth-child(1){
      margin-left: 5vw;
      width: 11.56vw;
      height: 3.37vw;
      cursor: pointer;
      border-radius: 6px;
      }
    }
    &>:nth-child(2){
      &>:nth-child(1){
      margin-left: 1vw;
      width: 11.56vw;
      height: 3.37vw;
      cursor: pointer;
      border-radius: 6px;
      }
    }
     }
    
  }
}
.part5 {
  &>:nth-child(1){
    width: 4.16vw;
    height: 4.16vw;
    position: absolute;
    top: 1.96vw;
    left: 36.45vw;
  }
  &>:nth-child(2){
    width: 4.16vw;
    height: 4.16vw;
    position: absolute;
    top: 1.96vw;
    left: 31.25vw;
  }
  &>:nth-child(3){
    position: absolute;
    top:1.4vw;
    font-size: 0.8rem;
    left: 41.66vw;
  }
}
.swiper-footer {
  height: 7.81vw !important;
  background: black !important;
  & img {
    margin-top: 0px;
  }
}

#kakaostory-share-button {
  width: 79px;
  height: 79px;
  background: url(~@/assets/cacao_btn.png) no-repeat;
  background-repeat: no-repeat;
  background-position: center 0;
  margin-left: 18px;
}
#kakaostory-share-button a {
  display: block;
  width: 100%;
  height: 100%;
}
#kakaostory-share-button a img {
  display: none;
}

@media (max-width: 1440px) and (min-width: 1024px) {
  .scroll {
    transform: scale(0.8);
  }
  .part2 {
    margin-top: 15%;
  }

  //   .part3 {
  //   }
  //   .part1,
  //   .part2,
  //   .part3,
  //   .part4 {
  //     transform: scale(0.8);
  //   }
}
.gift_icon {
  position: absolute;
  bottom: 70px;
  right: 70px;
}
.light {
  box-shadow: 0 0 8px #f4f0f1;
  -webkit-box-shadow: 0 0 8px #f4f0f1;
  -moz-box-shadow: 0 0 8px #f4f0f1;
  outline: none;
  border-radius: 50%;
  width: 16px;
  height: 16px;
}
.notlight {
  width: 16px;
  height: 16px;
}
.bottom_bar {
  bottom: 0;
  position: fixed;
  height: 200px;
  z-index: 1000;
  left: 50%;
  transform: translateX(-50%);
}
</style>
